import React from 'react';
import {Rate} from 'antd';


const columns = [
  {
    Header : 'User ID',
    accessor: 'user_id',
    key: 'user_id',
  
  },
   {
  Header : 'Rate',
  id:'rate',
  accessor: (text)=><span><Rate disabled allowHalf defaultValue={text.rate} /></span>,
  key: 'rate',

}, {
  Header : 'Review',
  accessor: 'review',
  key:'review'
},
];

export default columns
