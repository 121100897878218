import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";
import {REACT_APP_BASE_URL} from "../../constants/ActionTypes";
import axios from 'axios';
import ProfileForm from '../../components/modal/profileUpdate';
import {updateProfile} from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const headers= useSelector(({auth}) => auth.header);
  const user = useSelector(({auth}) => auth.authUser);
  const [visible, setVisible] = useState(false);

  const onCreate = values => {
    dispatch(updateProfile(values,headers));
    setVisible(false);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => {
          setVisible(true);
        }}>Update Account</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  useEffect(()=> {

  })

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src='https://via.placeholder.com/150x150'
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{user}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
      <ProfileForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>

  )
};

export default UserProfile;
