import React, { Component, createRef } from "react";
import {
  Modal,
  Button,
  Input,
  space,
  message,
  Row,
  Col,
  Spin,
  Select,
  InputNumber,
  Space,
} from "antd";
import axios from "util/Api";

import UploadAudio from "../buttons/uploadAudio";
import UploadVideo from "../buttons/uploadVideo";
import CK from "../editers/ck";
import Selector from "../inputs/Selector";
import UploadAudioWithReciters from "../buttons/UploadAudioWithReciters";
import { REACT_APP_BASE_URL } from "../../constants/ActionTypes";

import UploadImg from "../Images/uploadimg";
import convertMsToHM from "../../util/convertMsToHM";
import convertHMroMs from "../../util/convertHMtoMs";
const { Option } = Select;

const contentTypeList = ["audio", "video"];

const uploadRef = React.createRef();

class NewChapter extends Component {
  state = {
    title: "",
    desc: "",
    imgLoading: "",
    loading: false,
    visible: this.props.visible || false,
    validation: false,
    audio_url: "",
    type: "standard",
    bookType: "",
    content_type: "audio",
    image_url: "",
    files: [],
    contestTime: "48:00",
  };

  handleFilesChanges = (files) => {
    this.setState({ files });
  };

  componentDidMount() {
    const { url, id, book_id, headers, status, chapterId, data } = this.props;
    localStorage.setItem("audio_url", "");
    console.log(data);
    if (status === "create") {
      console.log(data);
      this.setState({
        bookType: data.type,
        content_type: data.type === "contest_program" ? "video" : "audio",
      });
    }
    if (status === "edit") {
      axios
        .get(`${url}/${book_id}/chapters/${chapterId}`, { headers })
        .then((res) => {
          const {
            title,
            desc,
            audio_url,
            type,
            image_url,
            content_type,
            counter_duration_ms,
          } = res.data.data;
          this.setState({
            title,
            image_url,
            content_type,
            desc,
            audio_url,
            type,
            contestTime: counter_duration_ms
              ? convertMsToHM(counter_duration_ms)
              : data.bookType === "contest_program"
              ? "48:00"
              : null,
            bookType: data.type,
          });
          // localStorage.setItem("desc", res.data.data.desc);
        })

        .catch((err) =>
          err.response.data.errors.map((error) => message.error(error.msg, 5))
        );

      axios
        .get(`${REACT_APP_BASE_URL}chapters/${chapterId}/reciters`, { headers })
        .then((res) => {
          console.log(res);
          res.data.data &&
            this.setState({
              files: res.data.data.map((d) => ({
                reciterId: d.reciter_id,
                audio_url: d.audio_url,
              })),
            });
        })
        .catch((err) =>
          err.response.data.errors.map((error) => message.error(error.msg, 5))
        );
    }
  }

  handleChange = (name, val) => {
    this.setState({ [name]: val });
  };

  handleOk = () => {
    const { audio_url, contestTime, type, bookType } = this.state;
    const { url, id, book_id, headers, status, chapterId, data } = this.props;

    if (this.state.bookType === "quran") {
      this.setState({ loading: true }, () => {
        const body = {
          ...this.state,

          desc: this.state.desc,
          //audio_url: localStorage.getItem("audio_url") || audio_url,
        };

        if (status === "create") {
          axios
            .post(`${url}/${book_id}/chapters`, body, { headers })
            .then((res) =>
              axios.post(
                `${REACT_APP_BASE_URL}chapters/${res.data.data.id}/reciters`,
                this.state.files.map((d) => ({
                  reciter_id: d.reciterId,
                  audio_url: d.audio_url,
                })),
                { headers }
              )
            )
            .then((res) => {
              console.log(res);
              this.props.action();
            })
            .catch((err) => {
              console.log(err);
              message.error(err.response.data.errors[0].msg);
              this.setState({ loading: false });
            });
        }
        if (status === "edit") {
          axios
            .put(`${url}/${book_id}/chapters/${chapterId}`, body, { headers })
            .then((res) =>
              axios.post(
                `${REACT_APP_BASE_URL}chapters/${chapterId}/reciters`,
                this.state.files.map((d) => ({
                  reciter_id: d.reciterId,
                  audio_url: d.audio_url,
                })),
                { headers }
              )
            )
            .then((res) => {
              this.props.action();
            })
            .catch((err) => {
              message.error(err.response.data.errors[0].msg);
              this.setState({ loading: false });
            });
        }
      });
    } else
      this.setState({ loading: true }, () => {
        const body = {
          ...this.state,
          type:
            bookType === "contest_program"
              ? bookType
              : bookType === "program"
              ? bookType
              : type,
          counter_duration_ms: contestTime && convertHMroMs(contestTime),
          desc: this.state.desc,
          audio_url: localStorage.getItem("audio_url") || audio_url,
        };

        if (status === "create") {
          axios
            .post(`${url}/${book_id}/chapters`, body, { headers })
            .then((res) => {
              this.props.action();
            })
            .catch((err) => {
              message.error(err.response.data.errors[0].msg);
              this.setState({ loading: false });
            });
        }
        if (status === "edit") {
          axios
            .put(`${url}/${book_id}/chapters/${chapterId}`, body, { headers })
            .then((res) => {
              this.props.action();
            })
            .catch((err) => {
              message.error(err.response.data.errors[0].msg);
              this.setState({ loading: false });
            });
        }
      });
  };

  handleCancel = () => {
    this.props.action();
  };

  handelInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getProgramContent = () => {
    const { headers } = this.props;
    const {
      validation,
      bookType,
      image_url,
      imgLoading,
      audio_url,
      content_type,
      contestTime,
    } = this.state;
    return (
      <>
        <Row>
          <Col span={12}>
            <h4>Title</h4>
            <Input
              placeholder="Title"
              name="title"
              value={this.state.title}
              onChange={this.handelInput}
            />
            {validation && !this.state.title && (
              <p className="input-validation">this feild is requierd!</p>
            )}
            <br />
            <h4>Content type</h4>
            <Select
              style={{ width: "100%" }}
              value={this.state.content_type}
              onChange={(selected) => this.setState({ content_type: selected })}
            >
              {contentTypeList.map((item, i) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
            <br />
            <br />
            {bookType === "contest_program" && (
              <label>
                <h4>Contest duration time</h4>
                <Space>
                  <>
                    <h6>Hours</h6>
                    <InputNumber
                      value={Number(contestTime.split(":")[0])}
                      onChange={(val) =>
                        this.setState({
                          contestTime: `${val}:${contestTime.split(":")[1]}`,
                        })
                      }
                    />
                  </>
                  <>
                    <h6>Minutes</h6>
                    <InputNumber
                      value={Number(contestTime.split(":")[1])}
                      onChange={(val) =>
                        this.setState({
                          contestTime: `${contestTime.split(":")[0]}:${val}`,
                        })
                      }
                    />
                  </>
                </Space>
              </label>
            )}
            <h4>Upload {content_type}</h4>
            {content_type === "audio" ? (
              <UploadAudio
                endpoint="books"
                setAudio={(audio) => this.setState({ audio_url: audio })}
                headers={headers}
                audio_url={this.state.audio_url}
              />
            ) : (
              <UploadVideo
                endpoint="books"
                headers={headers}
                video_url={this.state.audio_url}
              />
            )}
          </Col>
          <input
            type="file"
            id="chapter_img"
            onChange={this.handleFile}
            ref={(e) => (uploadRef.current = e)}
            style={{ display: "none" }}
          />
          <Col span={12}>
            <h4>Image </h4>
            <Spin spinning={imgLoading}>
              {" "}
              <UploadImg
                getParentElem={() => uploadRef.current.click()}
                endPoint={"chapter_img"}
                imgUrl={image_url}
              />
            </Spin>
          </Col>
        </Row>
        <h4>description</h4>
        <CK
          name="desc"
          action={this.handleChange}
          data={this.state.desc}
          height={370}
        />
        {validation && !localStorage.getItem("desc") && (
          <p className="input-validation">this feild is requierd!</p>
        )}
      </>
    );
  };

  handleFile = (e) => {
    e.preventDefault();
    const { headers } = this.props;
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    this.setState({ imgLoading: true }, () => {
      axios
        .post(`${REACT_APP_BASE_URL}uploads/chapter/img`, formData, {
          headers,
        })
        .then((res) =>
          this.setState(
            {
              image_url: res.data.data.file_url,
            },
            () => this.setState({ imgLoading: false })
          )
        )
        .catch((err) => {
          this.setState({ imgLoading: false });
          message.error(err.response.data.errors[0].msg);
        });
    });
  };

  chapterContent = () => {
    const { headers } = this.props;
    const { validation, bookType, image_url, audio_url, content_type } =
      this.state;
    switch (bookType) {
      case "contest_program":
        return this.getProgramContent();
      case "standard":
        return (
          <>
            <Row>
              <Col order={0} span={12}>
                {
                  <UploadAudio
                    endpoint="books"
                    headers={headers}
                    audio_url={this.state.audio_url}
                  />
                }
                <br />
              </Col>

              <Col span={4}>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.type}
                  onChange={(selected) => this.setState({ type: selected })}
                >
                  <Option value="standard">Standard</Option>
                  <Option value="quote">Quote</Option>
                  <Option value="introduction">Introduction</Option>
                  <Option value="conclusion">Conclusion</Option>
                </Select>
              </Col>

              <Col span={8}>
                <Input
                  style={{
                    direction: "rtl",
                  }}
                  placeholder="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handelInput}
                />
                {validation && !this.state.title && (
                  <p className="input-validation">this feild is requierd!</p>
                )}
              </Col>
            </Row>
            <br />
            <br />

            <CK
              name="desc"
              action={this.handleChange}
              data={this.state.desc}
              height={370}
            />
            {validation && !localStorage.getItem("desc") && (
              <p className="input-validation">this feild is requierd!</p>
            )}
          </>
        );

      case "quran":
        return (
          <>
            <Row>
              <Col order={0} span={12}>
                <UploadAudioWithReciters
                  files={this.state.files}
                  onFilesChange={this.handleFilesChanges}
                  endpoint="quran"
                  headers={headers}
                  audio_url={this.state.audio_url}
                />
                <br />
              </Col>

              <Col span={4}>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.type}
                  onChange={(selected) => this.setState({ type: selected })}
                >
                  <Option value="standard">Standard</Option>
                </Select>
              </Col>
              <Col span={8}>
                <Input
                  style={{
                    direction: "rtl",
                  }}
                  placeholder="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handelInput}
                />
                {validation && !this.state.title && (
                  <p className="input-validation">this feild is requierd!</p>
                )}
              </Col>
            </Row>
            <br />
            <br />

            <CK
              name="desc"
              action={this.handleChange}
              data={this.state.desc}
              height={370}
            />
            {validation && !localStorage.getItem("desc") && (
              <p className="input-validation">this feild is requierd!</p>
            )}
          </>
        );
      case "program":
        return this.getProgramContent();
      default:
        return (
          <>
            <Row>
              <Col order={2} span={24}>
                <h4>Audio</h4>
                <UploadAudio
                  endpoint="books"
                  headers={headers}
                  audio_url={this.state.audio_url}
                />
                <br />
              </Col>

              <Col span={24}>
                <h4>
                  Title <span className="req-astrick">*</span>
                </h4>
                <Input
                  style={{
                    direction: "ltr",
                  }}
                  placeholder="Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handelInput}
                />
                {validation && !this.state.title && (
                  <p className="input-validation">this feild is requierd!</p>
                )}
                <br /> <br />
              </Col>
            </Row>
            <br />
            <br />
          </>
        );
    }
  };

  render() {
    const { headers } = this.props;
    const { visible, validation, loading, bookType } = this.state;
    return (
      <Modal
        title="Add New Chapter"
        visible={visible}
        onOk={this.handleOk}
        style={{
          top: bookType === "noval" || bookType === "theater_play" ? 50 : 10,
        }}
        width={
          bookType === "noval" || bookType === "theater_play" ? "50%" : "100%"
        }
        confirmLoading={loading}
        onCancel={this.handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Row justify="center">
          <Col span={6}></Col>
        </Row>
        {this.chapterContent()}
      </Modal>
    );
  }
}

export default NewChapter;
