import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Modify from "./booksManager/components/Create-EditBook";
import ModifyCurated from "./curated/components/ModifyCurated";
import asyncComponent from "util/asyncComponent";
import BookRates from "./reviews/components/bookRates";
import NotFound from "./notFound/notFound";
import CreateEdit from "./pages/components/create-edit";

const App = ({ match }) => {
  const { user_role } = useSelector(({ auth }) => auth);
  console.log(55, match);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}voice-overs/:id/relations`}
          component={asyncComponent(() => import("./voiceOversRelations"))}
        />
        <Route
          path={`${match.url}voice-overs`}
          component={asyncComponent(() => import("./voiceOvers"))}
        />

        <Route
          path={`${match.url}inapp/group/details/:id`}
          component={asyncComponent(() =>
            import("./inapp/group/pages/newGroupContainer")
          )}
        />
        <Route
          path={`${match.url}inapp/group/new-group`}
          component={asyncComponent(() =>
            import("./inapp/group/pages/newGroupContainer")
          )}
        />
        <Route
          path={`${match.url}inapp/group`}
          component={asyncComponent(() => import("./inapp/group"))}
        />
        <Route
          path={`${match.url}inapp/messages`}
          component={asyncComponent(() => import("./inapp/messages"))}
        />
        <Route
          path={`${match.url}inapp/actions`}
          component={asyncComponent(() => import("./inapp/actions"))}
        />
        <Route
          path={`${match.url}inapp/criteria`}
          component={asyncComponent(() => import("./inapp/criteria"))}
        />

        <Route
          path={`${match.url}versions/details/:id`}
          component={asyncComponent(() => import("./versions/details"))}
        />
        <Route
          path={`${match.url}versions/details`}
          component={asyncComponent(() => import("./versions/details"))}
        />
        <Route
          path={`${match.url}versions`}
          component={asyncComponent(() => import("./versions"))}
        />

        <Route
          path={`${match.url}requestedBooks`}
          component={asyncComponent(() => import("./requestedBooks"))}
        />

        <Route
          path={`${match.url}inquiries`}
          component={asyncComponent(() =>
            import("./faqs/components/inquiries")
          )}
        />
        <Route
          path={`${match.url}businesses/logs`}
          component={asyncComponent(() =>
            import("./businesses/pages/businessLogs")
          )}
        />
        <Route
          path={`${match.url}businesses/details/:id`}
          component={asyncComponent(() => import("./businesses/details"))}
        />
        <Route
          path={`${match.url}businesses/details`}
          component={asyncComponent(() => import("./businesses/details/index"))}
        />
        <Route
          path={`${match.url}businesses`}
          component={asyncComponent(() => import("./businesses"))}
        />
        <Route
          path={`${match.url}support-table`}
          component={asyncComponent(() =>
            import("./customerCare/components/supportTable")
          )}
        />
        <Route
          path={`${match.url}faqs`}
          component={asyncComponent(() => import("./faqs"))}
        />

        <Route
          path={`${match.url}promo/details/:id`}
          component={asyncComponent(() =>
            import("./promoCode/pages/createEdit")
          )}
        />
        <Route
          path={`${match.url}promo/details`}
          component={asyncComponent(() =>
            import("./promoCode/pages/createEdit")
          )}
        />
        <Route
          path={`${match.url}promo`}
          component={asyncComponent(() => import("./promoCode"))}
        />

        <Route
          path={`${match.url}customer-care`}
          component={asyncComponent(() => import("./customerCare"))}
        />

        <Route
          path={`${match.url}departments/:vid/vacancy/vacancy_applicants/:id`}
          component={asyncComponent(() =>
            import("./careers/Components/applicants")
          )}
        />
        <Route
          path={`${match.url}departments/:id`}
          component={asyncComponent(() => import("./careers/Components/jobs/"))}
        />
        <Route
          path={`${match.url}departments`}
          component={asyncComponent(() => import("./careers"))}
        />

        <Route
          path={`${match.url}booksManager/voice-over/:voiceoverid`}
          component={asyncComponent(() => import("./booksManager"))}
        />
        <Route
          path={`${match.url}booksManager/ramadan/:type`}
          component={asyncComponent(() => import("./booksManager"))}
        />
        <Route
          path={`${match.url}booksManager/:type`}
          component={asyncComponent(() => import("./booksManager"))}
        />
        <Route
          path={`${match.url}booksManager`}
          component={asyncComponent(() => import("./booksManager"))}
        />
        <Route
          path={`${match.url}categories/:id/books`}
          component={asyncComponent(() => import("./categories/categoryBooks"))}
        />
        <Route
          path={`${match.url}tags/tag/:id/relations`}
          component={asyncComponent(() => import("./tagRelations"))}
        />
        <Route
          path={`${match.url}tags/tag/:id`}
          component={asyncComponent(() => import("./modifyTag"))}
        />
        <Route
          path={`${match.url}tags/tag/create`}
          component={asyncComponent(() => import("./modifyTag"))}
        />
        <Route
          path={`${match.url}tags`}
          component={asyncComponent(() => import("./tags"))}
        />

        <Route
          path={`${match.url}categories/:type`}
          component={asyncComponent(() => import("./categories"))}
        />
        <Route
          path={`${match.url}authors`}
          component={asyncComponent(() => import("./authors"))}
        />
        <Route
          path={`${match.url}freeBook`}
          component={asyncComponent(() => import("./freeBook"))}
        />
        <Route
          path={`${match.url}quickReads/ramadan/:type`}
          component={asyncComponent(() => import("./quickReads"))}
        />
        <Route
          path={`${match.url}quickReads`}
          component={asyncComponent(() => import("./quickReads"))}
        />
        {
          <Route
            path={`${match.url}users`}
            component={asyncComponent(() => import("./users"))}
          />
        }
        <Route
          path={`${match.url}interests`}
          component={asyncComponent(() => import("./interests"))}
        />
        {
          <Route
            path={`${match.url}subscribers`}
            component={asyncComponent(() => import("./subscribers"))}
          />
        }
        <Route
          path={`${match.url}curated`}
          component={asyncComponent(() => import("./curated"))}
        />
        <Route path={`/curated_book/create`} component={ModifyCurated} />
        <Route exact path="/curated_book/:id" component={ModifyCurated} />

        <Route path={`/pages/create`} component={CreateEdit} />
        <Route exact path="/pages/:id" component={CreateEdit} />

        {
          <Route
            exact
            path="/reviews/:id"
            render={(routeProps) => <BookRates />}
            component={BookRates}
          />
        }
        {
          <Route
            path={`${match.url}reviews`}
            component={asyncComponent(() => import("./reviews"))}
          />
        }
        <Route path={`/createBook/:type`} component={Modify} />
        <Route path={`/createBook`} component={Modify} />
        <Route
          exact
          path="/book/:id/logs"
          component={asyncComponent(() => import("./bookLogs"))}
        />

        <Route exact path="/book/:id" component={Modify} />
        <Route
          path={`${match.url}pages`}
          component={asyncComponent(() => import("./pages"))}
        />

        <Route
          path={`${match.url}shortcast/hubs/:id/podcasts/:eid/episode`}
          component={asyncComponent(() => import("./podcast/episodes/"))}
        />
        <Route
          path={`${match.url}shortcast/hubs/:id/podcast`}
          component={asyncComponent(() => import("./podcast/hub"))}
        />
        <Route
          path={`${match.url}shortcast`}
          component={asyncComponent(() => import("./podcast"))}
        />

        <Route
          path={`${match.url}:depId/jobform`}
          component={asyncComponent(() =>
            import("./careers/Components/jobForm")
          )}
        />
        <Route
          path={`${match.url}:depId/:id`}
          component={asyncComponent(() =>
            import("./careers/Components/jobForm")
          )}
        />
        <Route
          path={`${match.url}cards-creater`}
          component={asyncComponent(() => import("./cardsCreater"))}
        />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default App;
