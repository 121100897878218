import React,{useState,useEffect} from 'react';
import { Card} from 'antd';
import Info from './info';
import {useSelector} from 'react-redux'


const CreateEdit =(props) => {
    const headers= useSelector(({auth}) => auth.header);
    const [id,setId] =useState(props.match.params.id || null);
    const [type,setType] = useState(props.match.params.id ? 'edit':'create')

    useEffect(()=>{
        localStorage.removeItem('content')
        localStorage.removeItem('content_ar')
    },[])
    return(
        <Card>
            <Info type={type} id={id} headers={headers}/>
        </Card>
    )

}

export default CreateEdit;