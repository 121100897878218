import React from 'react';
import {ShrinkOutlined,MinusOutlined,CloseOutlined,ArrowsAltOutlined} from '@ant-design/icons';
import {Modal,Row,Col,Space,Button} from 'antd';
import SubscriberTab from './subscriberTab'
import './style.css';
import {useSelector} from 'react-redux'

const SubscribersTabs = ({id}) =>{
    const {subscriberTabs} = useSelector(({subscription})=>subscription)

    return(
        
        <div className='subscribers-tabs'>
           {subscriberTabs.map((item,i)=><SubscriberTab data={item.data} id={item.id} email={item.email} key={i}/>)}
        </div>
    )

}

export default SubscribersTabs