import React, { Component } from 'react';
import Player from '../inputs/AudioPlayer.js';
import { Button, Spin, Row, Col, Space, Popconfirm, message, Select } from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { REACT_APP_BASE_URL } from '../../constants/ActionTypes.js'
import './audioStyle.css'
const { Option } = Select;

const InputRef = React.createRef();

class UploadAudioWithReciters extends Component {
  state = {
    loading: false,
    selectedFile: null,
    msg: '',
    color: '',
    src: this.props.audio_url,
    del: true,
    reciters: [],
    selectedReciter: undefined,

  };

  handleReciter = (param) => {
    this.setState({ selectedReciter: param })
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0], loading: true }, () => { this.onFileUpload() });
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    const { endpoint, headers, id, onFilesChange, files } = this.props
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    axios
      .post(`${REACT_APP_BASE_URL}uploads/${endpoint}/audio`, formData, { headers })
      .then(res => {
        console.log(res)
        onFilesChange([...files, {
          reciterId: this.state.selectedReciter,
          audio_url: res.data.data.file_url,
        }])
        this.setState({ loading: false });
        this.setState({ selectedReciter: undefined })

        // localStorage.setItem('audio_url', res.data.data.file_url);
        // this.setState({ src: res.data.data.file_url });
        //this.setState({ del: true })

      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false });
        err.response.data.errors.map(error => message.error(error.msg, 5))
      })
  };

  // Api request to get data 
  componentDidMount() {
    const { headers } = this.props
    axios
      .post(`${REACT_APP_BASE_URL}quran_reciters/datatable`, {
        "pageSize": 100,
        "page": 1,
        "filtered": [],
        "sorted": [{
          "id": "id",
          "desc": false
        }]
      }, { headers })
      .then(res => {
        console.log(res)
        if (res.data) {
          console.log(res.data.data)
          this.setState({ reciters: res.data.data })
        }
      })
      // .then(() => this.props.updated())
      .catch(err => console.log(err.response))
  }
  render() {
    const { src, loading, del } = this.state
    const { files } = this.props
    console.log('files', files)
    const selectedIds = (files ?? []).map(d => d.reciterId);

    return (
      <div>
        <input type="file" onChange={this.onFileChange} ref={InputRef} style={{ display: 'none' }} />
        {loading ? <Row justify='center'><Spin size="large" /></Row> : <>
          <Row>
            <Col span={4}>{
              <label>
                <h4>Reciter</h4>
              </label>

            }</Col>
            <Col span={12}>
              <label>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.selectedReciter}
                  onChange={this.handleReciter}
                >
                  {this.state.reciters?.filter(d => selectedIds.includes(d.id) == false).map(reciter => (
                    <Option key={reciter.id + ''} value={reciter.id}>{reciter.name}</Option>
                  ))}

                </Select>
              </label>

            </Col>
            <Col span={6}>
              <Button disabled={!(this.state.selectedReciter > 0)} icon={<UploadOutlined />} onClick={() => InputRef.current.click()} >Upload Audio</Button>

            </Col>

          </Row>
        </>}
        <Row>

          {files.map(file => (
            <React.Fragment key={file.reciterId}>
              <Col span={2} >
                {file.audio_url ?
                  <Popconfirm placement="topLeft" title='Are you sure you want to delete audio?' onConfirm={() => {
                    this.props.onFilesChange(files.filter(d => d.reciterId != file.reciterId))
                    // localStorage.setItem('audio_url', '');
                    // this.setState({ del: false })
                    // this.setState({ src: '' });
                  }} okText="Yes" cancelText="No">
                    <Button
                      danger
                      type="text"
                      icon={<CloseOutlined />}
                      size='small'
                    />
                  </Popconfirm>
                  : <div></div>}
              </Col>
              <Col span={4} >
                <label>
                  <h4>{this.state.reciters.find(d => d.id == file.reciterId)?.name}</h4>
                </label>
              </Col>
              <Col span={18} >
                {file.audio_url ?
                  <Player src={file.audio_url} /> : <div></div>}
              </Col>
            </React.Fragment>

          ))}


        </Row>



      </div>
    )
  }
}

export default UploadAudioWithReciters;

