import React,{useState} from 'react';
import {ShrinkOutlined,MinusOutlined,CloseOutlined,ArrowsAltOutlined} from '@ant-design/icons';
import {Modal,Row,Col,Space,Button} from 'antd';
import {deleteSubscriberTab} from '../../appRedux/actions/Subscription'
import './style.css';
import {useDispatch} from 'react-redux'
import EditSubscriptionModal from '../../components/modal/subscriberModal/editSubscriperModal'

const SubscriberTab = ({id,email,data}) =>{
    const dispatch =useDispatch();
    const [visible,setVisible] = useState(true);
 
    const modalAction = () =>{
        setVisible(!visible);
    }

    return(
        <>
        {!visible && <div onClick={modalAction} className='subscriber-tab'>
            <Row justify='space-between'>
            <Col className='ant-modal-title ' span={16}>{email}</Col>
            <Col>
            <Space>
                <button className='icon-btn'span={4} ><ArrowsAltOutlined onClick={modalAction} /></button>
                <button className='icon-btn' span={4}><CloseOutlined onClick={()=>dispatch(deleteSubscriberTab(id))} /></button>
            </Space>
            </Col>
        </Row>
        </div>}
        {visible && <EditSubscriptionModal data={data} id={id} action={modalAction} visible={visible}/>}
        </>
    )

}

export default SubscriberTab