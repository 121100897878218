import React,{useEffect,useState} from 'react';
import {Card,Button,message} from "antd";
import columns from './bookColumns'
import {useSelector} from 'react-redux';
import DataTable from '../../../components/dataTable/dataTable';
import { PropertySafetyFilled } from '@ant-design/icons';
import PageBreadCrumb from '../../../util/pageBreadCrumb';

const  BookRates = (props)=> {
  const headers= useSelector(({auth}) => auth.header);
  const [visible,setVisible] = useState(false);
  const [data,setData] = useState({
    id:props.location.aboutProps?props.location.aboutProps.id : localStorage.getItem('id'),
    title:props.location.aboutProps?props.location.aboutProps.title : localStorage.getItem('title')});

  useEffect(()=> {
    if (props.location.aboutProps){
      localStorage.setItem('id',props.location.aboutProps.id)
      localStorage.setItem('title',props.location.aboutProps.title)
    }
  })

  return (
    <div>
      <PageBreadCrumb history={[{title:'Reviews',link:'/reviews'},{title:'Book Rates'}]}/>
      <h2 style={{marginBottom:'20px'}} >{ data.title +' book rates'}</h2>

      <DataTable endPoint={`book_ratings/datatable?id=${data.id}`} columns={columns} pageSize={10}  />

    </div>
  );

}

export default BookRates;
